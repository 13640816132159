<template>
  <b-card title="Tình hình khen thưởng kỷ luật theo từng Khoa">
    <app-echart-bar
      :option-data="option"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: 'value',
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: [],
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '100px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            name: 'Khen thưởng',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barWidth: '35%',
            itemStyle: {
              color: '#299AFF',
            },
          },
          {
            name: 'Kỷ luật',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barWidth: '35%',
            itemStyle: {
              color: '#28dac6',
              barBorderRadius: [0, 10, 10, 0],
            },
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters( {
      countStudentByItem: 'chart/countStudentByItem',
    }),
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getCountStudentByItem: 'chart/getCountStudentByItem',
    }),
    async getDataFromStore(){
      this.isLoading = true
      try {
        await this.getCountStudentByItem(this.filter)
        if (this.countStudentByItem.length > 0 ) {
          this.option.yAxis[0].data = []
          this.option.series[0].data = []
          this.option.series[1].data = []
          
          this.countStudentByItem.forEach((item, key) => {
            this.option.yAxis[0].data.push(item.departmentName)
            this.option.series[0].data.push(item.countStudentAward)
            this.option.series[1].data.push(item.countStudentDisciplines)
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>
