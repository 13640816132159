<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Phổ điểm học viên theo từng môn học</b-card-title>
      <b-dropdown
        dropright
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="FilterIcon"
          />
        </template>
        <b-dropdown-item
          v-for="(item, index) in itemSubject"
          @click="selectItem(item)"
          :key="index">
          {{ item.subjectName }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <chartjs-component-polar-area-chart
        ref="barChart1"
        :height="400"
        :data="polarChart.data"
        :options="polarChart.options"
      />
    </b-card-body>
    <b-card class="text-center mb-2" > {{ this.subTitle }} </b-card>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardHeader, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ChartjsComponentPolarAreaChart from "@/views/admin/category/charts/component/ChartjsComponentPolarAreaChart.vue";
import { $themeColors } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ChartjsComponentPolarAreaChart,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BDropdown,
    BDropdownItem,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      itemSubject: [],
      subTitle: null,
      filterCountPoint: {
        subjectId: null
      },
      checkLoadSubTitle: false,
      polarChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            position: 'right',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 10,
              fontColor: chartColors.labelColor,
            },
          },
          tooltips: {
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scale: {
            scaleShowLine: true,
            scaleLineWidth: 1,
            ticks: {
              display: false,
              fontColor: chartColors.labelColor,
            },
            reverse: false,
            gridLines: {
              display: false,
            },
          },
          animation: {
            animateRotate: false,
          },
        },
        data: {
          labels: ['A - P', 'B', 'C', 'D', 'F'],
          datasets: [
            {
              label: 'Population (millions)',
              backgroundColor: [
                chartColors.successColorShade,
                chartColors.infoColorShade,
                chartColors.primaryColorShade,
                chartColors.warningColorShade,
                chartColors.greyColor,
              ],
              data: [],
              borderWidth: 0,
            },
          ],
        },
      },
    }
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await Promise.all([
        this.getDataFromStore(),
        this.getSubjects()
      ])
    },
    'filter.yearId': async function () {
      await Promise.all([
        this.getDataFromStore(),
        this.getSubjects()
      ])
    },
    'filter.semesterId': async function () {
      await Promise.all([
        this.getDataFromStore(),
        this.getSubjects()
      ])
    }
  },
  computed: {
    ...mapGetters( {
      subjectsByYearAndSemester: 'chart/subjectsByYearAndSemester',
      countPointBySubject: 'chart/countPointBySubject',
    }),
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getSubjectsByYearAndSemester: 'chart/getSubjectsByYearAndSemester',
      getCountPointBySubject: 'chart/getCountPointBySubject'
    }),
    async getDataFromStore(){
      this.isLoading = true
      try {
        await this.getCountPointBySubject(this.filter)
        if (this.countPointBySubject.length > 0) {
          this.polarChart.data.datasets[0].data = []
          this.polarChart.data.datasets[0].data.push(
            this.countPointBySubject[0].pointA,
            this.countPointBySubject[0].pointB,
            this.countPointBySubject[0].pointC,
            this.countPointBySubject[0].pointD,
            this.countPointBySubject[0].pointF)
        }
        this.checkLoadSubTitle = true;
        if (this.countPointBySubject.length === 0 ) {
          this.checkLoadSubTitle = false;
        }
        this.$refs.barChart1.renderChart(this.polarChart.data, this.polarChart.options)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async selectItem(item) {
      this.filter.subjectId = item?.subjectId
      await this.getDataFromStore()
      if (item != null) {
        this.checkLoadSubTitle === true ? this.subTitle = item.subjectName : ''
        if (!this.checkLoadSubTitle) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Môn học '+ item.subjectName + ' chưa có điểm!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },

    async getSubjects() {
      this.itemSubject = []
      await this.getSubjectsByYearAndSemester(this.filter)
      if (this.subjectsByYearAndSemester.length > 0) {
        this.subjectsByYearAndSemester.forEach((item, key) => {
          this.itemSubject.push(this.subjectsByYearAndSemester[key])
        })
      }
    }
  }
}

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

</script>
